import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../layouts/layout"
import Paging from "../components/Paging"
import SEO from "../components/SEO/index.js"
import PostItem from "../components/Blog/PostItem"
import Collection from "../components/Collection"

import API from "../js/api"

class Page extends React.Component {
  state = {
    collections: Array.apply(null, Array(12)), count: 50
  }

  componentDidMount(){
    fetch("https://api.svgrepo.com/collections/").then(res => res.json()).then(res => {
      let collections = res.collections;
      this.setState({collections, count: res.count})
    })
  }

  render() {

    return (
      <Layout isHome={true}>

        <div className="contentListing homeListing">

          <div className="homeFeatures">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#d2536f" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"><path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path></svg>
            <h4>Easy To Search and Find</h4>
            <p>Using ML powered search, find the right vector you are looking for easier</p>
          </div>


          <div className="homeFeatures">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#d2536f" strokeWidth="3" strokeLinecap="round" strokeLinejoin="arcs"><circle cx="6" cy="6" r="3"></circle><circle cx="6" cy="18" r="3"></circle><line x1="20" y1="4" x2="8.12" y2="15.88"></line><line x1="14.47" y1="14.48" x2="20" y2="20"></line><line x1="8.12" y1="8.12" x2="12" y2="12"></line></svg>
            <h4>Stying & Remixing</h4>
            <p>Make basic changes on an icon or vector witout needing a design software</p>
          </div>

          <div className="homeFeatures">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#d2536f" strokeWidth="3" strokeLinecap="round" strokeLinejoin="arcs"><polyline points="16 18 22 12 16 6"></polyline><polyline points="8 6 2 12 8 18"></polyline></svg>
            <h4>Optimized Vectors</h4>
            <p>All served icons and vectors are optimized with our <a href="https://github.com/svg/svgo" target="_blank" rel="nofollow">SVGO</a> based compressor.</p>
          </div>

          <div className="homeFeatures">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#d2536f" strokeWidth="3" strokeLinecap="round" strokeLinejoin="arcs"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
            <h4>Community Powered</h4>
            <p>From me, to us. <a href="https://upload.svgrepo.com" target="_blank" rel="nofollow">Share your work</a>, help the community grow and get link attributions</p>
          </div>

          <div className="homeFeatures">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#d2536f" strokeWidth="3" strokeLinecap="round" strokeLinejoin="arcs"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
            <h4>Commercial Friendly</h4>
            <p>Most icons and vectors useable for your commercial projects without any royalties</p>
          </div>

          <div className="homeFeatures">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#d2536f" strokeWidth="3" strokeLinecap="round" strokeLinejoin="arcs"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
            <h4>Always Free, No Premium</h4>
            <p>We don't show premium content while you are trying to find free assets.</p>
          </div>
        </div>

        <h2>{API.get("homeContentTitle")}</h2>
        <p>{API.get("homeContentDesc")}</p>

                <div className="tabs">
                  <div className="leftBlank"></div>
                  <div className="rightBlank"></div>
                  <ul className={"tabHolderAll"}>
                    <Link to={`/collections/all`}><li>All<span> Icons</span></li></Link>
                    <Link to={`/collections/monocolor/`}><li>Monocolor</li></Link>
                    <Link to={`/collections/multicolor/`}><li>Multicolor</li></Link>
                    <Link to={`/collections/outlined/`}><li>Outlined</li></Link>
                    <Link to={`/collections/filled/`}><li>Filled</li></Link>
                  </ul>
                </div>
        <div className="collectionListing">
          {this.state.collections.map((collection, i) => {
            return(
              <Collection key={i} vectors={collection ? collection.preview : false} slug={collection ? collection.slug : false} count={collection ? collection.count : false}/>
            )
          })}
        </div>

        <Paging listing={"collections/all"} page={1} count={this.state.count} num={12}/>

        <SEO meta={{
          title: `${API.get("title")} - ${API.get("shortDesc")}`,
          noMainTitle: true,
          description: API.get("homeMetaDesc"),
          slug: `https://${API.get("domain")}/`
        }} />
      </Layout>
    )
  }
}

/*


<h2>Blog Posts</h2>
<p>See some insights into how to use SVG icons in most efficient way, download free vector packs with one click, get tips and tricks about SVG files and see our latest featured icons.</p>

<div className="contentListing">
  <PostItem
    post={{
      title: "Hold Your Breath: Rankin takes a positive look at 'authentic' love in the 2020s",
      category: "Photography"
    }}
  />
  <PostItem
    post={{
      title: "Explore the concept of Sin at the National Gallery with works by Jan Brueghel, Warhol, Emin and Mueck",
      category: "Inspiration"
    }}
  />
  <PostItem
    post={{
      title: "Paintings by Melbourne artist Prudence Flint of strong female characters deep in thought",
      category: "Art & Culture"
    }}
  />
  <PostItem
    post={{
      title: "Explore the concept of Sin at the National Gallery with works by Jan Brueghel, Warhol, Emin and Mueck",
      category: "Inspiration"
    }}
  />
  <PostItem
    post={{
      title: "Paintings by Melbourne artist Prudence Flint of strong female characters deep in thought",
      category: "Art & Culture"
    }}
  />
  <PostItem
    post={{
      title: "Explore the concept of Sin at the National Gallery with works by Jan Brueghel, Warhol, Emin and Mueck",
      category: "Inspiration"
    }}
  />
</div>

*/

export default Page
